@use '@angular/material' as mat;
@use 'palettes';
@use 'variables/colors';
@use 'variables/spacings';

@mixin dialog-theme {
	$custom-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: mat.m2-define-palette(palettes.$palette-primary, 700),
				accent: mat.m2-define-palette(palettes.$palette-secondary, 700)
			)
		)
	);

	.q--dialog {
		.mat-mdc-dialog-title {
			--mat-dialog-headline-padding: 0;

			margin: unset !important;

			&::before {
				content: unset !important;
			}
		}

		.mat-mdc-dialog-content {
			--mat-dialog-with-actions-content-padding: 0;
		}

		.mat-mdc-dialog-actions {
			--mat-dialog-actions-padding: 0;

			min-height: unset !important;
		}

		.mat-mdc-dialog-component-host > * {
			padding: spacings.$spacing-m;
		}
	}

	@include mat.dialog-theme($custom-theme);
}

@include dialog-theme;
